<template>
  <div v-if="renderComponent && post" class="post-detail">
    <UserProfileHeader :user="post.user" :defaultProfilePhoto="defaultProfilePhoto" />
    <h2>{{ post.title }}</h2>
    <div class="post-content" v-html="renderedPostContent"></div>
    <small>{{ formatDate(post.createdAt) }}</small>
    <div class="post-views">
      <small>조회수: {{ post.views }}</small>
    </div>
    <div class="reactions">
      <button :disabled="isReacting" @click="handleReaction('like')" class="reaction-button like" :class="{ active: userReaction === 'like' }">
        ▲추천 {{ post.likes || 0 }}
      </button>
      <button :disabled="isReacting" @click="handleReaction('dislike')" class="reaction-button dislike" :class="{ active: userReaction === 'dislike' }">
        ▼비추 {{ post.dislikes || 0 }}
      </button>
    </div>
    <div class="comments-section">
      <h3>댓글</h3>
      <div v-for="comment in comments" :key="comment.id" class="comment">
        <img :src="comment.user.photoURL || defaultProfilePhoto" alt="Profile Photo" class="profile-photo" @click="handleCommentUserClick(comment)">
        <div class="comment-content">
          <div class="comment-header">
            <span>{{ comment.user.displayName || comment.user.name }}</span>
          </div>
          <div v-html="renderedCommentContent(comment.content)"></div>
          <img v-if="comment.imageUrl" :src="comment.imageUrl" alt="Comment Image" class="comment-image">
          <div class="comment-reactions">
            <button :disabled="comment.isReacting" @click="handleCommentReaction(comment.id, 'like')" class="reaction-button like" :class="{ active: comment.userReaction === 'like' }">
              <font-awesome-icon icon="thumbs-up" /> {{ comment.likes || 0 }}
            </button>
            <button :disabled="comment.isReacting" @click="handleCommentReaction(comment.id, 'dislike')" class="reaction-button dislike" :class="{ active: comment.userReaction === 'dislike' }">
              <font-awesome-icon icon="thumbs-down" /> {{ comment.dislikes || 0 }}
            </button>
          </div>
        </div>
      </div>
      <div v-if="isAuthenticated" class="new-comment">
        <h4>새 댓글</h4>
        <div class="comment-header">
          <img :src="userProfile.photoURL || defaultProfilePhoto" alt="Profile Photo" class="profile-photo">
          <span>{{ userProfile.displayName || userProfile.name }}</span>
        </div>
        <textarea v-model="newCommentContent" placeholder="댓글" class="small-textarea"></textarea>
        <div class="file-upload">
          <label>사진:</label>
          <input type="file" @change="handleFileUpload">
        </div>
        <button @click="submitComment">확인</button>
      </div>
    </div>
  </div>
  <div v-else class="loading">
    <p>로딩 중...</p>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { doc, getDoc, updateDoc, collection, addDoc, getDocs, query, orderBy, runTransaction } from "firebase/firestore";
import { ref as storageRef, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { db, storage, auth } from "../firebase";
import { useStore } from 'vuex';
import { onAuthStateChanged } from "firebase/auth";
import UserProfileHeader from '../components/UserProfileHeader.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';



export default {
  mounted() {
    if (this.post) {
      // 동적으로 메타 제목과 설명을 설정
      document.title = this.post.title;
      
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.post.description || '');
      } else {
        // 만약 메타 설명 태그가 없다면 새로 생성
        const descriptionTag = document.createElement('meta');
        descriptionTag.setAttribute('name', 'description');
        descriptionTag.setAttribute('content', this.post.description || '');
        document.head.appendChild(descriptionTag);
      }
    }
  },
  data() {
    return {
      post: {
        title: '예시 제목',  // 실제 데이터로 바꿔야 함
        description: '이것은 예시 설명입니다.',  // 실제 데이터로 바꿔야 함
        user: {
          displayName: '사용자 이름'  // 실제 사용자 이름으로 바꿔야 함
        },
        createdAt: new Date().toISOString()  // 실제 게시물 생성 날짜로 바꿔야 함
      }
    };
  },

  components: {
    UserProfileHeader,
    FontAwesomeIcon
  },

  setup() {
    
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const post = ref(null);
    const comments = ref([]);
    const newCommentContent = ref('');
    const commentImageFile = ref(null);
    const userReaction = ref(null);
    const isReacting = ref(false);
    const renderComponent = ref(false);
    const defaultProfilePhoto = '/default-profile-photo.jpg';
    const userProfile = ref({
      uid: '',
      uniqueId: '',
      photoURL: '',
      displayName: '',
      name: ''
    });
    

    const isAuthenticated = computed(() => store.getters.isAuthenticated);

    const renderedPostContent = computed(() => {
      return (post.value.content || '').replace(/\[img\](.*?)\[\/img\]/g, '<img src="$1" class="inserted-image" style="max-width: 50%;" />');
    });

    const formatDate = (dateString) => {
      const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
      return new Date(dateString).toLocaleDateString('ko-KR', options);
    };

    const handleFileUpload = (event) => {
      commentImageFile.value = event.target.files[0];
    };

    const uploadImage = async (file) => {
      if (!file) return '';
      const storageRefInstance = storageRef(storage, `images/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRefInstance, file);

      return new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {},
          (error) => {
            console.error('Image upload failed:', error);
            reject(error);
          },
          async () => {
            const url = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(url);
          }
        );
      });
    };

    const submitComment = async () => {
      if (!newCommentContent.value.trim()) {
        alert("내용을 입력하세요.");
        return;
      }

      const user = auth.currentUser;

      if (!userProfile.value.photoURL || userProfile.value.photoURL === defaultProfilePhoto) {
        alert("프로필 사진을 업로드한 후 댓글을 달 수 있습니다.");
        return;
      }

      let imageUrl = '';

      try {
        if (commentImageFile.value) {
          imageUrl = await uploadImage(commentImageFile.value);
        }

        const userDocRef = doc(db, "users", user.uid);
        const userDocSnap = await getDoc(userDocRef);

        let userData = {
          uid: user.uid,
          uniqueId: '',
          displayName: '',
          photoURL: user.photoURL || defaultProfilePhoto,
          name: ''
        };

        if (userDocSnap.exists()) {
          userData = userDocSnap.data();
          userData.uid = user.uid;
          userData.uniqueId = userData.uniqueId || user.uid;
          userData.displayName = userData.displayName || userData.name || '';
          userData.name = userData.name || '';
        } else {
          userData.uniqueId = user.uid;
          userData.displayName = '';
          userData.name = '';
        }

        const newComment = {
          user: {
            uid: userData.uid,
            uniqueId: userData.uniqueId,
            displayName: userData.displayName,
            photoURL: userData.photoURL,
            name: userData.name
          },
          content: newCommentContent.value,
          imageUrl: imageUrl,
          createdAt: new Date().toISOString(),
          likes: 0,
          dislikes: 0
        };

        const postId = route.params.postId;
        const boardId = route.params.boardId; // boardId를 추가로 가져옵니다.
        const commentsRef = collection(db, "boards", boardId, "posts", postId, "comments");
        await addDoc(commentsRef, newComment);
        comments.value.push(newComment);

        newCommentContent.value = '';
        commentImageFile.value = null;

        console.log("New comment added:", newComment);
      } catch (error) {
        console.error("Error adding comment: ", error);
      }
    };

   const fetchComments = async () => {
  try {
  
    const postId = route.params.postId;
    const boardId = route.params.boardId;

    const commentsQuery = query(
      collection(db, "boards", boardId, "posts", postId, "comments"),
      orderBy("createdAt", "desc")
    );
    
    const querySnapshot = await getDocs(commentsQuery);

    comments.value = await Promise.all(querySnapshot.docs.map(async docSnap => {
      const data = docSnap.data();
     

      // 사용자 정보 처리
      const userRef = doc(db, "users", data.user.uid);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        data.user.photoURL = userDoc.data().photoURL || defaultProfilePhoto;
        data.user.displayName = userDoc.data().displayName || userDoc.data().name || '';
        data.user.uniqueId = userDoc.data().uniqueId || data.user.uid;
        data.user.name = userDoc.data().name || '';
      } else {
        data.user.photoURL = defaultProfilePhoto;
        data.user.displayName = 'Unknown';
        data.user.uniqueId = data.user.uid;
        data.user.name = '';
      }

      // 사용자 반응 처리
      const userId = store.getters.userProfile ? store.getters.userProfile.uid : null;
      if (userId) {
        const reactionRef = doc(db, "boards", boardId, "posts", postId, "comments", docSnap.id, "reactions", userId);
        const reactionDoc = await getDoc(reactionRef);

        data.userReaction = reactionDoc.exists() ? reactionDoc.data().type : null;
        data.isReacting = false;
      } else {
        data.userReaction = null;
        data.isReacting = false;
      }

      return { id: docSnap.id, ...data };
    }));

    console.log('Comments fetched:', comments.value);
  } catch (error) {
    console.error('Error fetching comments:', error);
  }
};



    const renderedCommentContent = (content) => {
      return content.replace(/\[img\](.*?)\[\/img\]/g, '<img src="$1" class="inserted-image" style="max-width: 50%;" />');
    };

    const handleReaction = async (reaction) => {
      if (!isAuthenticated.value) {
        alert("로그인 후 이용 가능합니다.");
        return;
      }

      if (isReacting.value) return;

      isReacting.value = true;

      const postId = route.params.postId;
      const boardId = route.params.boardId; // boardId를 추가로 가져옵니다.
      const userId = store.getters.userProfile.uid;
      const reactionRef = doc(db, "boards", boardId, "posts", postId, "reactions", userId);

      try {
        await runTransaction(db, async (transaction) => {
          const reactionDoc = await transaction.get(reactionRef);
          const postRef = doc(db, "boards", boardId, "posts", postId);

          if (!reactionDoc.exists()) {
            const newReaction = { type: reaction };
            transaction.set(reactionRef, newReaction);

            if (reaction === "like") {
              transaction.update(postRef, { likes: (post.value.likes || 0) + 1 });
              post.value.likes = (post.value.likes || 0) + 1;
              userReaction.value = reaction; // 즉시 반영
            } else {
              transaction.update(postRef, { dislikes: (post.value.dislikes || 0) + 1 });
              post.value.dislikes = (post.value.dislikes || 0) + 1;
              userReaction.value = reaction; // 즉시 반영
            }
          } else {
            const currentReaction = reactionDoc.data().type;
            if (currentReaction === reaction) {
              transaction.delete(reactionRef);

              if (reaction === "like") {
                transaction.update(postRef, { likes: (post.value.likes || 0) - 1 });
                post.value.likes = (post.value.likes || 0) - 1;
              } else {
                transaction.update(postRef, { dislikes: (post.value.dislikes || 0) - 1 });
                post.value.dislikes = (post.value.dislikes || 0) - 1;
              }
              userReaction.value = null; // 즉시 반영
            } else {
              transaction.update(reactionRef, { type: reaction });

              if (reaction === "like") {
                transaction.update(postRef, {
                  likes: (post.value.likes || 0) + 1,
                  dislikes: (post.value.dislikes || 0) - 1
                });
                post.value.likes = (post.value.likes || 0) + 1;
                post.value.dislikes = (post.value.dislikes || 0) - 1;
                userReaction.value = reaction; // 즉시 반영
              } else {
                transaction.update(postRef, {
                  dislikes: (post.value.dislikes || 0) + 1,
                  likes: (post.value.likes || 0) - 1
                });
                post.value.dislikes = (post.value.dislikes || 0) + 1;
                post.value.likes = (post.value.likes || 0) - 1;
                userReaction.value = reaction; // 즉시 반영
              }
            }
          }
        });
      } catch (error) {
        console.error("Failed to handle reaction: ", error);
      } finally {
        isReacting.value = false;
      }
    };

    const handleCommentReaction = async (commentId, reaction) => {
      if (!isAuthenticated.value) {
        alert("로그인 후 이용 가능합니다.");
        return;
      }

      const commentIndex = comments.value.findIndex(comment => comment.id === commentId);
      if (commentIndex === -1 || comments.value[commentIndex].isReacting) return;

      comments.value[commentIndex].isReacting = true;

      const postId = route.params.postId;
      const boardId = route.params.boardId; // boardId를 추가로 가져옵니다.
      const userId = store.getters.userProfile.uid;
      const commentReactionRef = doc(db, "boards", boardId, "posts", postId, "comments", commentId, "reactions", userId);

      try {
        await runTransaction(db, async (transaction) => {
          const reactionDoc = await transaction.get(commentReactionRef);
          const commentRef = doc(db, "boards", boardId, "posts", postId, "comments", commentId);

          if (!reactionDoc.exists()) {
            const newReaction = { type: reaction };
            transaction.set(commentReactionRef, newReaction);

            if (reaction === "like") {
              transaction.update(commentRef, { likes: (comments.value[commentIndex].likes || 0) + 1 });
              comments.value[commentIndex].likes = (comments.value[commentIndex].likes || 0) + 1;
              comments.value[commentIndex].userReaction = reaction; // 즉시 반영
            } else {
              transaction.update(commentRef, { dislikes: (comments.value[commentIndex].dislikes || 0) + 1 });
              comments.value[commentIndex].dislikes = (comments.value[commentIndex].dislikes || 0) + 1;
              comments.value[commentIndex].userReaction = reaction; // 즉시 반영
            }
          } else {
            const currentReaction = reactionDoc.data().type;
            if (currentReaction === reaction) {
              transaction.delete(commentReactionRef);

              if (reaction === "like") {
                transaction.update(commentRef, { likes: (comments.value[commentIndex].likes || 0) - 1 });
                comments.value[commentIndex].likes = (comments.value[commentIndex].likes || 0) - 1;
              } else {
                transaction.update(commentRef, { dislikes: (comments.value[commentIndex].dislikes || 0) - 1 });
                comments.value[commentIndex].dislikes = (comments.value[commentIndex].dislikes || 0) - 1;
              }
              comments.value[commentIndex].userReaction = null; // 즉시 반영
            } else {
              transaction.update(commentReactionRef, { type: reaction });

              if (reaction === "like") {
                transaction.update(commentRef, {
                  likes: (comments.value[commentIndex].likes || 0) + 1,
                  dislikes: (comments.value[commentIndex].dislikes || 0) - 1
                });
                comments.value[commentIndex].likes = (comments.value[commentIndex].likes || 0) + 1;
                comments.value[commentIndex].dislikes = (comments.value[commentIndex].dislikes || 0) - 1;
                comments.value[commentIndex].userReaction = reaction; // 즉시 반영
              } else {
                transaction.update(commentRef, {
                  dislikes: (comments.value[commentIndex].dislikes || 0) + 1,
                  likes: (comments.value[commentIndex].likes || 0) - 1
                });
                comments.value[commentIndex].dislikes = (comments.value[commentIndex].dislikes || 0) + 1;
                comments.value[commentIndex].likes = (comments.value[commentIndex].likes || 0) - 1;
                comments.value[commentIndex].userReaction = reaction; // 즉시 반영
              }
            }
          }
        });
      } catch (error) {
        console.error("Failed to handle comment reaction: ", error);
      } finally {
        comments.value[commentIndex].isReacting = false;
      }
    };

    const fetchUserReaction = async () => {
      if (!isAuthenticated.value) return;

      const postId = route.params.postId;
      const boardId = route.params.boardId; // boardId를 추가로 가져옵니다.
      const userId = store.getters.userProfile.uid;
      const reactionRef = doc(db, "boards", boardId, "posts", postId, "reactions", userId);
      const reactionDoc = await getDoc(reactionRef);

      if (reactionDoc.exists()) {
        userReaction.value = reactionDoc.data().type;
      } else {
        userReaction.value = null;
      }
    };

    const setPost = (postData) => {
      postData.user = postData.user || {};
      postData.user.uniqueId = postData.user.uniqueId || postData.user.uid;

      post.value = {
        ...postData,
        likes: postData.likes || 0,
        dislikes: postData.dislikes || 0,
        user: postData.user
      };
      renderComponent.value = true;

      console.log('Set post:', post.value);
    };

    const refreshUserProfile = async () => {
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          const userDocRef = doc(db, "users", user.uid);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            userProfile.value.uid = user.uid;
            userProfile.value.uniqueId = userData.uniqueId || user.uid;
            userProfile.value.photoURL = userData.photoURL || defaultProfilePhoto;
            userProfile.value.displayName = userData.displayName || userData.name || '';
            userProfile.value.name = userData.name || '';
          } else {
            userProfile.value.uid = user.uid;
            userProfile.value.uniqueId = user.uid;
            userProfile.value.photoURL = defaultProfilePhoto;
            userProfile.value.displayName = '';
            userProfile.value.name = '';
          }
        } else {
          userProfile.value.uid = '';
          userProfile.value.uniqueId = '';
          userProfile.value.photoURL = defaultProfilePhoto;
          userProfile.value.displayName = '';
          userProfile.value.name = '';
        }
        renderComponent.value = true;
      });
    };

    const goToUserProfile = (uniqueId) => {
      if (uniqueId && uniqueId !== 'anonymous') {
        router.push({ name: 'UserProfile', params: { uniqueId } });
      } else {
        console.error("uniqueId is missing or invalid");
      }
    };

    const handleProfileClick = (uniqueId) => {
      try {
        goToUserProfile(uniqueId);
      } catch (error) {
        console.error("Error navigating to user profile:", error);
        alert("사용자 프로필 페이지로 이동 중 오류가 발생했습니다. 콘솔을 확인하세요.");
      }
    };

    const handleCommentUserClick = (comment) => {
      try {
        goToUserProfile(comment.user?.uniqueId || comment.user?.uid);
      } catch (error) {
        console.error("Error navigating to comment user profile:", error);
        alert("댓글 작성자 프로필 페이지로 이동 중 오류가 발생했습니다. 콘솔을 확인하세요.");
      }
    };

    onMounted(async () => {
      renderComponent.value = true;
      try {
        const docRef = doc(db, "boards", route.params.boardId, "posts", route.params.postId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const post = { id: docSnap.id, ...docSnap.data() };

          await updateDoc(docRef, {
            views: (post.views || 0) + 1
          });

          post.views += 1;

          post.user = post.user || {};
          post.user.uniqueId = post.user.uniqueId || post.user.uid;

          setPost(post);
          await fetchComments();
          await fetchUserReaction();
        } else {
          console.error("No such document!");
        }
      } catch (error) {
        console.error("Error getting document:", error);
      }
      await store.dispatch('initializeAuthState');
      await refreshUserProfile();
    });

    return {
      post,
      comments,
      newCommentContent,
      commentImageFile,
      userReaction,
      isReacting,
      renderComponent,
      defaultProfilePhoto,
      userProfile,
      isAuthenticated,
      renderedPostContent,
      formatDate,
      handleFileUpload,
      uploadImage,
      submitComment,
      fetchComments,
      renderedCommentContent,
      handleReaction,
      handleCommentReaction,
      fetchUserReaction,
      handleProfileClick,
      handleCommentUserClick
    };
  }
};
</script>


<style scoped>
.post-detail {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.profile-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.post-content {
  width: 1400px;          /* 기본 너비 (데스크톱에서) */
  height: auto;           /* 고정된 높이 대신 콘텐츠에 맞춰 자동으로 높이 조정 */
 
  line-height: 1.8;       /* 줄 간격을 넓게 설정 */
  margin: 20px auto;      /* 상하단 여백과 가운데 정렬 */
 
}

/* 모바일 화면용 스타일 */
@media screen and (max-width: 768px) {
  .post-content {
    width: 700px;         /* 모바일에서 너비를 700px로 설정 */
   
  }
}




.inserted-image {
  display: block;
  margin: 10px 0;
}

.post-views {
  margin-top: 10px;
  font-size: 14px;
  color: #999;
}

.reactions {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.reaction-button {
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.reaction-button.like {
  background-color: #007bff;
  color: #fff;
}

.reaction-button.like:hover {
  background-color: #0056b3;
}

.reaction-button.dislike {
  background-color: #dc3545;
  color: #fff;
}

.reaction-button.dislike:hover {
  background-color: #b52b35;
}

.reaction-button.active {
  font-weight: bold;
  opacity: 0.8;
}

.comments-section {
  margin-top: 40px;
}

.comment {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.comment-header {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 5px;
}

.comment-content {
  flex: 1;
}

.comment-image {
  max-width: 100%;
  margin-top: 10px;
}

.comment-reactions {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.comment-reactions .reaction-button {
  padding: 5px 10px;
  font-size: 12px;
}

.new-comment {
  margin-top: 40px;
}

.new-comment h4 {
  margin-bottom: 20px;
}

.new-comment .small-textarea {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
}

.new-comment .file-upload {
  margin-bottom: 10px;
}

.new-comment button {
  padding: 10px 15px;
  font-size: 14px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.new-comment button:hover {
  background-color: #0056b3;
}

.loading {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.loading p {
  font-size: 16px;
  color: #666;
}
</style>

