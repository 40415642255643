<template>
  <div class="liked-users">
    <h2>좋아요한 사용자</h2>
    <div class="user-list">
      <button @click="fetchLikedUsers" class="btn-blue">좋아요한 사용자 보기</button>
      <div v-if="loading" class="loading-message">로딩 중...</div>
      <div v-else-if="error" class="error-message">{{ error }}</div>
      <ul v-else>
        <li v-for="user in randomLikedUsers" :key="user.uniqueId" class="user-item">
          <img 
            :src="user.photoURL || defaultPicture" 
            @error="handleImageError" 
            @click="goToUserPage(user.uniqueId)" 
            alt="User's profile picture" 
            class="profile-picture"
          >
          <h3>{{ user.displayName }}</h3>
          <p>ID: {{ user.uniqueId }}</p>
          <p>{{ user.name }}</p>
          <div v-if="user.userReactions">
            <h4>좋아요 목록:</h4>
            <ul>
              <!-- 좋아요 목록에서 랜덤으로 5명만 보여줍니다 -->
              <li v-for="key in getRandomReactions(user.userReactions)" :key="key">
                <img 
                  :src="findUserPhoto(key) || defaultPicture" 
                  @error="handleImageError" 
                  @click="goToUserPage(key)" 
                  alt="Liked user's profile picture" 
                  class="profile-picture"
                >
                {{ findUserName(key) }}
              </li>
            </ul>
          </div>
        </li>
      </ul>
      <div v-if="randomLikedUsers.length === 0" class="no-users-message">좋아요한 사용자가 없습니다.</div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
  name: 'LikedUsers',
  setup() {
    const store = useStore();
    const router = useRouter();
    const loading = ref(false);
    const error = ref(null);
    const defaultPicture = 'https://via.placeholder.com/150';

    // Fisher-Yates shuffle 알고리즘
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    };

    // 좋아요한 사용자들을 무작위로 섞고 최대 5명까지 반환
    const randomLikedUsers = computed(() => {
      const users = [...store.state.likedUsers];
      shuffleArray(users);
      return users.slice(0, 5);
    });

    // 사용자의 좋아요 목록에서 랜덤으로 5명만 반환하는 함수
    const getRandomReactions = (reactions) => {
      const reactionKeys = Object.keys(reactions).filter(key => reactions[key] === 'like');
      shuffleArray(reactionKeys);
      return reactionKeys.slice(0, 5);
    };

    const fetchLikedUsers = async () => {
      loading.value = true;
      error.value = null; // 에러 메시지 초기화
      try {
        await store.dispatch('fetchLikedUsers');
      } catch (e) {
        error.value = `Failed to fetch users: ${e.message || 'Unknown error'}`;
      } finally {
        loading.value = false;
      }
    };

    const handleImageError = (event) => {
      event.target.src = defaultPicture;
    };

    const findUserPhoto = (uniqueId) => {
      const user = store.state.allUsers.find(user => user.uniqueId === uniqueId);
      return user ? user.photoURL || defaultPicture : defaultPicture;
    };

    const findUserName = (uniqueId) => {
      const user = store.state.allUsers.find(user => user.uniqueId === uniqueId);
      return user ? user.name || 'Name not available' : 'Unknown';
    };

    const goToUserPage = (uniqueId) => {
      router.push({ name: 'UserProfile', params: { uniqueId } }); // 라우터 파라미터를 uniqueId로 설정
    };

    return {
      randomLikedUsers,
      fetchLikedUsers,
      loading,
      error,
      handleImageError,
      findUserPhoto,
      findUserName,
      getRandomReactions,
      goToUserPage
    };
  },
};
</script>



<style>
.btn-blue {
    background-color: #007bff;
    border: none;
    color: white;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 2px 1px;
    cursor: pointer;
    border-radius: 12px;
    transition-duration: 0.4s;
}

.btn-blue:hover {
    background-color: #0056b3;
    color: white;
}
.profile-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.user-item {
  display: flex;
  align-items: center;
  gap: 10px;
}
</style>





