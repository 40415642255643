<template>
  <div :style="containerStyle">
    <h2>게시물 목록</h2>
    <div class="board-posts-container">
      <div v-if="isLoading">로딩 중...</div>
      <div v-else>
        <div v-if="displayedPosts.length">
          <ul>
            <li v-for="post in displayedPosts" :key="post.id">
              <div class="post-item">
                <img :src="post.postThumbnail" alt="Post Thumbnail" class="thumbnail-image post-thumbnail" />
                <img :src="post.user?.photoURL || defaultProfilePhoto" alt="Profile Photo" class="profile-photo">
                <div class="post-info">
                  <router-link :to="{ name: 'PostView', params: { boardId: post.boardId, postId: post.id } }" class="post-title-link">
                    {{ post.title }}
                    <img :src="post.boardThumbnail" alt="Board Thumbnail" class="thumbnail-image board-thumbnail-larger" />
                  </router-link>
                  <p>작성일: {{ formatDate(post.createdAt) }}</p>
                </div>
                <div class="likes-info">
                  <span>{{ post.likes }}</span>
                  <i class="fas fa-thumbs-up"></i>
                </div>
              </div>
            </li>
          </ul>
          <div class="pagination">
            <button @click="changePage(1)" :disabled="currentPage === 1">1</button>
            <button @click="changePage(2)" :disabled="currentPage === 2 || totalPages < 2">2</button>
          </div>
        </div>
        <div v-else>
          <p>게시물이 없습니다.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { collection, getDocs, query } from 'firebase/firestore';
import { db } from '../firebase';

const boardPosts = ref([]);
const displayedPosts = ref([]);
const isLoading = ref(true);
const currentPage = ref(1);
const postsPerPage = 8;
const totalPages = ref(0);
const containerStyle = ref({ padding: '15px' });
const defaultProfilePhoto = '/default-profile-photo.jpg';

// 게시물 데이터를 가져오는 함수
const fetchBoardPosts = async () => {
  isLoading.value = true;
  try {
    const boardsQuery = query(collection(db, 'boards'));
    const boardsSnapshot = await getDocs(boardsQuery);

    const postsPromises = boardsSnapshot.docs.map(async (boardDoc) => {
      const boardId = boardDoc.id;
      const boardData = boardDoc.data();
      const boardPostsQuery = query(collection(db, 'boards', boardId, 'posts'));
      const boardPostsSnapshot = await getDocs(boardPostsQuery);

      return boardPostsSnapshot.docs.map((postDoc) => {
        const postData = postDoc.data();
        return {
          id: postDoc.id,
          boardId,
          ...postData,
          boardTitle: boardData.title,
          boardThumbnail: boardData.thumbnailUrl || 'default-thumbnail.jpg',
          postThumbnail: postData.thumbnailUrl || 'default-thumbnail.jpg',
          user: postData.user || { photoURL: defaultProfilePhoto, uniqueId: 'Unknown' },
          likes: postData.likes || 0,
          createdAt: postData.createdAt,
          score: calculatePostScore(postData),
        };
      });
    });

    // 모든 게시물을 가져오는 Promise를 병렬로 실행
    const posts = await Promise.all(postsPromises);
    boardPosts.value = posts.flat().sort((a, b) => b.score - a.score);
    totalPages.value = Math.ceil(boardPosts.value.length / postsPerPage);
    updateDisplayedPosts();
  } catch (error) {
    console.error('게시물 가져오기 중 오류 발생: ', error);
  } finally {
    isLoading.value = false;
  }
};

// 점수를 계산하는 함수
const calculatePostScore = (post) => {
  const now = new Date();
  const postDate = new Date(post.createdAt);
  const daysPassed = (now - postDate) / (1000 * 60 * 60 * 24); // 일 단위로 변환
  const freshnessScore = Math.max(0, 5 - daysPassed);
  return freshnessScore + (post.likes || 0);
};

// 날짜 형식을 포맷하는 함수
const formatDate = (date) => {
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  return new Date(date).toLocaleDateString(undefined, options);
};

// 현재 페이지에 맞는 게시물을 업데이트하는 함수
const updateDisplayedPosts = () => {
  const startIndex = (currentPage.value - 1) * postsPerPage;
  displayedPosts.value = boardPosts.value.slice(startIndex, startIndex + postsPerPage);
};

// 페이지 변경 함수
const changePage = (pageNumber) => {
  if (pageNumber > 0 && pageNumber <= totalPages.value) {
    currentPage.value = pageNumber;
    updateDisplayedPosts();
    window.scrollTo(0, 0);
  }
};

// 컴포넌트가 마운트될 때 게시물 데이터를 가져옴
onMounted(() => {
  fetchBoardPosts();
});
</script>

<style scoped>
.board-posts-container {
  padding: 15px;
}

.post-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 15px;
  transition: background-color 0.3s ease;
}

.post-item:hover {
  background-color: #e0e0e0;
}

.thumbnail-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 20px;
}

.board-thumbnail-larger {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 4px;
  margin-left: 10px;
}

.post-thumbnail {
  margin-right: 10px;
}

.profile-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 15px;
}

.post-info {
  flex-grow: 1;
}

.post-title-link {
  display: flex;
  align-items: center;
}

.post-info a {
  font-size: 1.1em;
  font-weight: bold;
  color: #333;
  text-decoration: none;
}

.post-info a:hover {
  text-decoration: underline;
  color: #007BFF;
}

.post-info p {
  margin: 5px 0;
  color: #555;
  font-size: 0.9em;
}

.likes-info {
  display: flex;
  align-items: center;
  font-size: 1.1em;
  font-weight: bold;
  color: #007BFF;
}

.likes-info i {
  margin-left: 5px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 15px;
}

/* 반응형 디자인 */
@media (max-width: 600px) {
  .post-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .likes-info {
    margin-top: 10px;
    align-self: flex-end;
  }

  .post-info {
    margin-top: 10px;
  }
}
</style>














