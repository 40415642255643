<template>
  <div class="post-header" @click="handleProfileClick">
    <img :src="userProfile.photoURL" alt="Profile Photo" class="profile-photo">
    <span>{{ userProfile.uniqueId || userProfile.uid }}</span>
  </div>
</template>

<script>
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
    defaultProfilePhoto: {
      type: String,
      default: '/default-profile-photo.jpg',
    },
  },
  data() {
    return {
      userProfile: {
        uniqueId: '',
        uid: '',
        photoURL: this.defaultProfilePhoto,
        displayName: '',
      },
    };
  },
  async mounted() {
    console.log('UserProfileHeader mounted with user:', this.user);
    await this.fetchUserProfile();
  },
  watch: {
    user: {
      immediate: true, // 컴포넌트가 마운트될 때 즉시 호출
      handler(newUser) {
        this.updateUserProfile(newUser);
      },
    },
  },
  methods: {
    async fetchUserProfile() {
      const userId = this.user.uid || this.user.uniqueId;
      if (!userId) {
        console.error("User UID is missing or invalid");
        return;
      }

      try {
        console.log('Fetching profile for user UID:', userId);
        const userDocRef = doc(db, 'users', userId);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          this.updateUserProfile(userData);
        } else {
          console.error('No such user document!');
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    },
    updateUserProfile(userData) {
      this.userProfile.uniqueId = userData.uniqueId || this.user.uid;
      this.userProfile.uid = this.user.uid || this.user.uniqueId;
      // 캐시 방지를 위해 쿼리 스트링 추가
      this.userProfile.photoURL = userData.photoURL 
        ? `${userData.photoURL}?t=${new Date().getTime()}` 
        : this.defaultProfilePhoto;
      this.userProfile.displayName = userData.displayName || '';
      console.log('Updated user profile data:', this.userProfile);
    },
    handleProfileClick() {
      const uniqueId = this.userProfile.uniqueId || this.userProfile.uid;
      console.log('Navigating to user profile with uniqueId:', uniqueId);

      if (uniqueId && uniqueId !== 'anonymous') {
        this.$router.push({ name: 'UserProfile', params: { uniqueId } })
          .catch(err => {
            console.error('Router push error:', err);
            window.location.href = `/user/${uniqueId}`;
          });
      } else {
        console.error('uniqueId is missing or invalid');
      }
    },
  },
};
</script>
<style scoped>
.post-header {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.profile-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

span {
  font-size: 1em;
}
</style>









